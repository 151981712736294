import { render, staticRenderFns } from "./ProductProfileList.vue?vue&type=template&id=347e2a3c&"
import script from "./ProductProfileList.vue?vue&type=script&lang=js&"
export * from "./ProductProfileList.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {NuxtDynamic: require('/builds/reifen_gundlach/frontend/node_modules/@blokwise/dynamic/src/components/Dynamic.vue').default})
