//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { defineComponent } from '@nuxtjs/composition-api'

export default defineComponent({
  name: 'ProductProfileList',
  props: {
    headline: {
      type: String,
      default: ''
    },
    productItems: {
      type: Array,
      default: () => []
    }
  }
})
